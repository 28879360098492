<template>
    <div class="reviews">
        <check-for-missing-integrations :integrations="['Facebook pages', 'Trustpilot review', 'Google MyBusiness']">
            <landing-page slot="no-integrations"
                          image="/images/integrations/cta-messages/reviews.png"
                          :headline="$t('reviews.onboarding.headline')"
                          :description="$t('reviews.onboarding.description')">
                <hr>

                <integration name="Facebook pages"
                             service="facebook-pages"
                             styling="landscape"
                             :border="true"
                             logo="/images/onboarding/facebook.svg"
                             :headline="$t('overview.onboarding.facebookPages.headline')"
                             :description="$t('reviews.onboarding.facebook.description')" />

                <br>

                <trustpilot />

                <br>

                <integration name="Google MyBusiness"
                             service="my-business"
                             styling="landscape"
                             :border="true"
                             logo="/images/onboarding/google-business.svg"
                             :headline="$t('reviews.onboarding.googleMyBusiness.headline')"
                             :description="$t('reviews.onboarding.googleMyBusiness.description')" />

                <hr>
            </landing-page>

            <div class="container-fluid">
                <div class="row">
                    <div class="col-xs-12">
                        <notice />
                    </div>

                    <div class="col-xs-12">
                        <card class="tour-step-1">
                            <review-sources @hasLoaded="startTourWhenReady()" />
                        </card>
                    </div>

                    <div class="col-lg-6">
                        <advice />
                    </div>
                    <div class="col-lg-6">
                        <review-distribution class="tour-step-3" />
                    </div>

                    <div class="col-lg-12">
                        <query-new-review />
                    </div>
                </div>

                <div class="row">
                    <div class="col-xs-12">
                        <review-list class="tour-step-4" />
                    </div>
                </div>
            </div>
        </check-for-missing-integrations>
    </div>
</template>

<style lang="scss" scoped>
    .btn {
        margin-top: 15px;
        display: block;
        float: left;
    }

    .help-link {
        display: block;
        float: left;
        margin: 23px 0 0 15px;
    }

    .btn-trustpilot {
        background: #f9a21e;
    }

    .btn-facebook {
        background: #405a94;
    }

    .btn-google-plus {
        background: #4780e7;
    }
</style>

<script>
import Banner from '@/app/onboarding/components/Banner'
import Card from '@/app/shared/components/Card'
import ReviewSources from '@/app/reviews/components/Sources'
import ReviewList from '@/app/reviews/components/list/Reviews'
import ReviewAdvice from '@/app/reviews/components/Advice'
import ReviewDistribution from '@/app/reviews/components/Distribution'
import Notice from '@/app/reviews/components/Notice'
import CheckForMissingIntegrations from '@/app/integrations/components/CheckForMissingIntegrations'
import LandingPage from '@/app/onboarding/components/LandingPage'
import Integration from '@/app/onboarding/components/integrations/Integration'
import Trustpilot from '@/app/onboarding/components/integrations/Trustpilot'
import QueryNewReview from '@/app/reviews/components/list/QueryNewReview'
import Advice from '@/app/reviews/components/Advice'

import hasIntegration from '@/mixins/integrations/hasIntegration'
import tourMixin from '@/mixins/tours/tourMixin'

export default {
    mixins: [hasIntegration, tourMixin],

    methods: {
        startTourWhenReady() {
            let options = {
                steps: [
                    {
                        element: '.tour-step-1',
                        intro: '<h5>Se dit online omdømme</h5><p>Her kan du se dit samlede omdømme på henholdsvis Facebook og Trustpilot</p>',
                        position: 'top',
                    },
                    {
                        element: '.tour-step-2',
                        intro: '<h5>Tips til et bedre omdømme</h5><p>Vores eksperter har skrevet tips og anbefalinger, der kan hjælpe dig med, hvor og hvordan du skal gøre en indsats for at få et bedre omdømme på internettet</p>',
                    },
                    {
                        element: '.tour-step-3',
                        intro: '<h5>Dine bedømmelser</h5><p>Med denne funktion kan du se, hvordan anmeldelserne af din forretning fordeler sig på en skala fra 1 til 5 stjerner</p>',
                        position: 'top',
                    },
                    {
                        element: '.tour-step-4',
                        intro: '<h5>Læs dine anmeldelser</h5><p>Her kan du læse anmeldelserne af din virksomhed og se hvor mange stjerner, du er blevet tildelt</p>',
                    },
                ]
            }

            if (this.isOnboarding) {
                options.steps.push({
                    element: '.tour-step-integration',
                    intro: '<h5>Fortsæt det gode arbejde</h5><p>Du har nu opsat din første integration i OP\'N. Klik på integrationssiden for at opsætte den næste.</p>',
                    position: 'bottom'
                })
            }

            this.startTour('review', options)
        }
    },

    components: {
        Banner,
        Card,
        ReviewList,
        ReviewSources,
        ReviewAdvice,
        ReviewDistribution,
        CheckForMissingIntegrations,
        LandingPage,
        Integration,
        Notice,
        QueryNewReview,
        Trustpilot,
        Advice,
    }
}
</script>
