<template>
    <div class="review-advice-box">
        <div class="row">
            <div class="col-xs-12">
                <card :headline="$t('reviews.advice.headline')" :description="$t('reviews.advice.description')" icon="pe-7s-bell">
                    <hr>

                    <div class="advice-list">
                        <template v-if="adviceList.length > 1">
                            <span class="control left pe-7s-angle-left" @click="previous"></span>
                            <span class="control right pe-7s-angle-right" @click="next"></span>
                        </template>

                        <div class="advice-container">
                            <div v-for="(advice, index) in adviceList" class="advice" :style="{'min-width': adviceWidth + 'px', 'margin-left': adviceMarginLeft(index)}" v-if="hasLoaded">
                                <template v-if="advice.type == 'tooFew'">
                                    <span class="headline" v-html="$t('reviews.advice.tips.moreReviews.heading', {index: index +1, source: advice.source})"></span>

                                    <p v-html="$t('reviews.advice.tips.moreReviews.tip', {numberOfReviews: advice.reviews, source: advice.source, review: $tc('reviews.reviews', advice.reviews)})"></p>

                                    <button v-if="identity.language.short == 'da'" class="btn btn-round btn-primary hidden-xs hidden-sm" data-toggle="modal" data-target="#tipsModal">
                                        {{$t('reviews.advice.tips.button')}}
                                    </button>
                                </template>

                                <template v-if="advice.type == 'tooLow'">
                                    <span class="headline" v-html="$t('reviews.advice.tips.betterRating.heading', {index: index +1, source: advice.source})"></span>

                                    <p v-html="$t('reviews.advice.tips.betterRating.tip', {source: advice.source, rating: advice.averageRating})"></p>

                                    <button v-if="identity.language.short == 'da'" class="btn btn-round btn-primary hidden-xs hidden-sm"data-toggle="modal" data-target="#tipsModal">
                                        {{$t('reviews.advice.tips.button')}}
                                    </button>
                                </template>
                            </div>

                            <div v-if="adviceList.length == 0" class="advice">
                                <span class="headline">{{$t('reviews.advice.tips.noReviews.headline')}}</span>

                                <p class="text-muted">{{$t('reviews.advice.tips.noReviews.tip')}}</p>

                                <button v-if="identity.language.short == 'da'" class="btn btn-round btn-primary hidden-xs hidden-sm"data-toggle="modal" data-target="#tipsModal">
                                    {{$t('reviews.advice.tips.button')}}
                                </button>
                            </div>
                        </div>
                    </div>
                </card>
            </div>
        </div>

        <!-- Tips Modal -->
        <div class="modal fade" id="tipsModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                        <h4 class="modal-title" id="myModalLabel">{{ $t('reviews.advice.tips.headline') }}</h4>
                    </div>
                    <div class="modal-body">
                        <p class="text-muted">{{ $t('reviews.advice.tips.button') }}</p>

                        <ol>
                            <li>{{ $t('reviews.advice.tips.line1') }}</li>
                            <li>{{ $t('reviews.advice.tips.line2') }}</li>
                            <li>{{ $t('reviews.advice.tips.line3') }}</li>
                            <li>{{ $t('reviews.advice.tips.line4') }}</li>
                            <li>{{ $t('reviews.advice.tips.line5') }}</li>
                            <li>{{ $t('reviews.advice.tips.line6') }}</li>
                            <li>{{ $t('reviews.advice.tips.line7') }}</li>
                        </ol>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary btn-rounded" data-dismiss="modal">{{ $t('reviews.advice.tips.close') }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .review-advice-box {
        .advice-list {
            position: relative;
            overflow: hidden;
            min-height: 190px;

            .control {
                cursor: pointer;
                position: absolute;
                font-size: 40pt;
                top: 50%;
                margin-top: -25px;

                &.left {
                    left: -20px;
                }

                &.right {
                    right: -20px;
                }
            }

            .advice-container {
                display: inline-flex;
                overflow: hidden;
                width: calc(100% - 100px);
                margin: 10px 50px;

                .advice {
                    .headline {
                        display: block;
                        font-size: 15pt;
                        margin-bottom: 10px;
                        font-weight: 200;

                        strong {
                            font-weight: 500;
                        }
                    }

                    p {
                        display: block;
                        font-size: 11pt;
                        line-height: 25px;
                        margin-bottom: 15px;
                    }
                }
            }
        }
    }

    #tipsModal {
        .modal-header{
            h4 {
                margin: 5px 0;
            }
        }
        li {
            margin-bottom: 15px;
        }
    }
</style>
<style lang="scss">
    .review-advice-box {
        .advice {
            strong {
                font-weight: 400;
            }
        }
    }
</style>

<script>
    import Loader from '@/app/shared/components/Loader'
    import Card from '@/app/shared/components/Card'

    import {mapGetters} from 'vuex'

    const ReviewService = require('@/services/reviews/ReviewService')

    import organization from '@/mixins/customer/organization'

    export default {
        mixins: [organization],

        data() {
            return {
                hasLoaded: false,
                reviews: {},
                threshold: 15,
                thresholdRating: 3,
                adviceWidth: 200,
                adviceVisible: 1
            }
        },

        computed: {
          ...mapGetters('identity', {
            identity: 'getIdentity'
          }),

            adviceList() {
                let list = [];

                const sources = Object.keys(this.reviews)

                let sourceTranslations = {
                    'facebook': 'Facebook',
                    'trustpilot': 'Trustpilot',
                    'google-my-business': 'Google',
                }

                sources.forEach((source) => {
                    const numberOfReviews = this.reviews[source]

                    if (numberOfReviews >= this.threshold) {
                        return
                    }

                    list.push({
                        type: 'tooFew',
                        source: sourceTranslations[source],
                        reviews: numberOfReviews
                    })
                })

                return list
            }
        },

        watch: {
            adviceContainerWidth() {
                this.adviceWidth = this.adviceContainerWidth
            }
        },

        mounted() {
            this.load()

            this.adviceWidth = (this.$el) ? this.$el.querySelector('.advice-container').offsetWidth : 150
        },

        methods: {
            next() {
                if (this.adviceVisible === this.adviceList.length) {
                    this.adviceVisible = 1
                    return;
                }

                this.adviceVisible++
            },

            previous() {
                if (this.adviceVisible === 1) {
                    this.adviceVisible = this.adviceList.length
                    return;
                }

                this.adviceVisible--
            },

            load() {
                this.hasLoaded = false;

                const sources = ['facebook', 'trustpilot', 'google-my-business']

                sources.forEach((source) => {
                    const options = {
                        limit: 1,

                        includes: ['source'],

                        sort: [
                            {
                                key: 'created',
                                direction: 'desc'
                            },
                            {
                                key: 'author',
                                direction: 'asc'
                            }
                        ],

                        filter_groups: [
                            {
                                filters: [
                                    {
                                        key: 'source.slug',
                                        value: source,
                                        operator: 'eq'
                                    }
                                ]
                            }
                        ]
                    };

                    ReviewService.findAll(options, (response) => {
                        const body = response.data
                        let total = body.total

                        if (this.reviews[source]) {
                            total += this.reviews[source]
                        }

                        this.hasLoaded = true
                        this.$set(this.reviews, source, total)
                    })
                })
            },

            adviceMarginLeft(index) {
                if (index !== 0) {
                    return null;
                }

                return '-' + (this.adviceWidth * (this.adviceVisible - 1)) + 'px';
            }
        },

        components: {
            Loader,
            Card
        }
    }
</script>
