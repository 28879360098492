<template>
    <div class="review-how-to">
        <card v-show="review" :headline="headline" :description="description" icon="pe-7s-car" id="suggested-review-response">
            <div class="container-fluid" v-if="review">
                <div class="row">
                    <div class="col-lg-4" style="margin-top: 30px;">
                        <div class="information-label">
                            <img class="review-source__logo" :src="reviewServiceLogo" alt="">
                            <span class="review-source__company">{{review.source.name}}</span>
                            <i class="fa fa-star star fa-fw" v-for="n in positiveStars" />
                            <i class="fa fa-star-o star fa-fw" v-for="n in negativeStars" /><br />
                            <span class="review-source__name">{{review.author}}</span>
                            <span class="review-source__date">{{review.created.date | moment("from", "now")}}</span>
                        </div>

                        <p class="review-text" v-html="review.review" v-if="review.review"></p>
                        <p class="review-text" v-else><i>{{$t('newsFeed.reviews.queryNewReview.positive.noReview')}}</i></p>
                    </div>

                    <template v-if="positiveStars <= 3">
                        <div class="col-lg-4" style="padding-top: 30px;">
                            <p><strong>{{$t('newsFeed.reviews.queryNewReview.tips.negative.intro')}}</strong></p>
                            <p style="font-size: 12px;">{{$t('newsFeed.reviews.queryNewReview.tips.negative.description')}}</p>
                            <ul style="font-size: 12px;">
                                <li v-for="n in 7">{{$t(`newsFeed.reviews.queryNewReview.tips.negative.list.item-${n}`)}}</li>
                            </ul>
                            <p style="font-size: 12px;">{{$t('newsFeed.reviews.queryNewReview.tips.negative.outro')}}</p>
                        </div>

                        <div class="col-lg-4" style="padding-top: 30px;">
                            <p style="padding: 0 10px;"><strong>{{$t('newsFeed.reviews.queryNewReview.tips.negative.template.intro')}}</strong></p>
                            <p style="font-style: italic; padding: 0 10px; font-size: 12px;" v-html="$t('newsFeed.reviews.queryNewReview.tips.negative.template.answer', {client: review.author, company: customer.name})"></p>
                        </div>
                    </template>

                    <template v-else>
                        <div class="col-lg-4" style="padding-top: 30px;">
                            <p><strong>{{$t('newsFeed.reviews.queryNewReview.tips.positive.intro')}}</strong></p>
                            <p style="font-size: 12px;" v-html="$t('newsFeed.reviews.queryNewReview.tips.positive.description')"></p>
                            <ul style="font-size: 12px;">
                                <li v-for="n in 3">{{$t(`newsFeed.reviews.queryNewReview.tips.positive.list.item-${n}`)}}</li>
                            </ul>
                            <p style="font-size: 12px;">{{$t('newsFeed.reviews.queryNewReview.tips.positive.outro')}}</p>
                        </div>

                        <div class="col-lg-4" style="padding-top: 30px;">
                            <p style="padding: 0 10px;"><strong>{{$t('newsFeed.reviews.queryNewReview.tips.positive.template.intro')}}</strong></p>
                            <p style="font-style: italic; padding: 0 10px; font-size: 12px;" v-html="$t('newsFeed.reviews.queryNewReview.tips.positive.template.answer', {client: review.author, company: customer.name})"></p>
                        </div>
                    </template>
                </div>
            </div>
        </card>
    </div>
</template>

<style lang="scss" scoped>
    @import '~@/assets/scss/_vars.scss';
    @import '~@/assets/scss/_mixins.scss';

    .star {
        color: #fac725;
    }

    ul {
        list-style: none;
        li {
            line-height: 20px;
            &:before {
                font-family: 'FontAwesome';
                content: '\f129';
                margin: 0 10px 0 -15px;
                color: #000;
            }
        }
    }

    .not-empty {
        display: inline-block;
        border-left: 2px solid #eaeaea;
        color: #888888;
        padding-left: 20px;
        &:empty {
            margin-top: 0;
        }
        &:not(:empty) {
            margin-top: 20px;
        }
    }

    .information-label {
        padding: 20px;
        background: #f9f9f9;
        border-radius: 3px;
        margin-bottom: 10px;
        position: relative;
    }

    .review-source__logo {
        width: 40px;
        float: left;
        display: block;
        margin-right: 10px;
    }

    .review-source__company {
        float: left;
        display: block;
        font-weight: 500;
        margin-right: 10px;
    }

    .review-source__name {
        font-style: italic;
    }

    .review-source__date {
        float: right;
        font-size: 10px;
        color: #888888;
    }

    .review-text {
        font-size: 14px;
        line-height: 20px;
        padding: 30px 30px 20px 20px;
        background: #f9f9f9;
        float: left;
        width: 100%;
        max-height: 300px;
        border-bottom: 5px solid #f9f9f9;
        border-top: 5px solid #f9f9f9;
        overflow-y: auto;
    }
</style>

<script>
    import Card from '@/app/shared/components/Card'
    const ReviewService = require('@/services/reviews/ReviewService')

    const VueScrollTo = require('vue-scrollto');

    import { mapGetters } from 'vuex'

    export default {
        data() {
            return {
                review: null,
                positiveStars: 0,
                negativeStars: 0
            }
        },

        computed: {
          ...mapGetters('identity', {
            identity: 'getIdentity'
          }),
          ...mapGetters('customer', {
            customer: 'getCustomer'
          }),

            headline() {
                if (this.positiveStars <= 3) {
                    return $t('newsFeed.reviews.queryNewReview.negative.headline')
                }

                return $t('newsFeed.reviews.queryNewReview.positive.headline')
            },

            description() {
                if (this.positiveStars <= 3) {
                    return $t('newsFeed.reviews.queryNewReview.negative.description')
                }

                return $t('newsFeed.reviews.queryNewReview.positive.description')
            },

            reviewServiceLogo() {
                if (!this.review.source) {
                    return
                }

                switch(this.review.source.slug) {
                    case 'trustpilot':
                        return '/images/review-sources/trustpilot-compact.png'
                    case 'facebook':
                        return '/images/review-sources/facebook-compact.png'
                    case 'google-my-business':
                        return '/images/review-sources/google-my-business-compact.png'
                }
            },

            reviewId() {
                const query = this.$route.query

                return query.reviewId
            }
        },

        watch: {
            reviewId() {
                this.load()
            }
        },

        mounted() {
            this.load()
        },

        methods: {
            load() {
                if (!this.reviewId) {
                    return
                }

                ReviewService.find(this.reviewId, {
                    includes: ['source']
                }, response => {
                    const data = response.data

                    this.review = data

                    this.positiveStars = this.review.rating
                    this.negativeStars = 5 - this.review.rating

                    setTimeout(this.scrollToElement, 250)
                }, error => {})
            },

            scrollToElement() {
                const element = this.$el.querySelector('#suggested-review-response')

                VueScrollTo.scrollTo(element, 400, {
                    offset: -100,
                    easing: 'ease-in-out'
                })
            }
        },

        components: {
            Card
        }
    }
</script>
