<template>
    <div class="review-notice">
        <transition name="slide-fade">
            <div class="alert alert-primary text-center" v-for="integration in integrations" :key="integration.id"
                 v-html="$t('reviews.loadingNotice', {name: name(integration)})">
                </div>
        </transition>
    </div>
</template>

<style lang="scss" scoped>
    .review-notice {
        .alert {
            font-size: 15px;
            padding: 15px;
        }
    }
</style>

<script>
    import {mapGetters} from 'vuex'
    import moment from 'moment'

    export default {
        data() {
            return {
                now: moment(),
                interval: null
            }
        },

        computed: {
          ...mapGetters('identity', {
            identity: 'getIdentity'
          }),

          ...mapGetters('customer', {
            customer: 'getCustomer'
          }),

            integrations() {
                if (!this.identity || !this.customer) {
                    return []
                }

                const customer = this.customer

                const validServices = [
                    'Facebook pages',
                    'Trustpilot review',
                    'Google MyBusiness'
                ]

                return customer.integrations.filter((integration) => {
                    const service = integration.service

                    if (!service) {
                        return false
                    }

                    const created = moment(integration.created.date)
                    const diffInMinutes = this.now.diff(created, 'minutes')

                    return validServices.indexOf(service.name) !== -1 && diffInMinutes <= 10
                })
            },
        },

        mounted() {
            this.interval = setInterval(this.tick, 3000)
        },

        destroyed() {
            clearInterval(this.interval)
        },

        methods: {
            tick() {
                this.now = moment()
            },

            name(integration) {
                let name = ''

                switch(integration.service.name) {
                    case 'Trustpilot review':
                        name = 'Trustpilot'
                        break;
                    default:
                        name = integration.service.name
                        break;
                }

                return name
            }
        }
    }
</script>
