<template>
    <div class="sources">
        <div class="container-fluid">
            <div class="row">
                <div class="text-center col-md-4" v-for="(source, index) in sources">

                    <hr class="visible-sm visible-xs" v-if="index > 0">

                    <div class="box">
                        <div class="logo-wrapper">
                            <img :src="reviewSourceImage(source)"/>
                        </div>
                    </div>

                    <div>
                        <div v-if="hasLoaded">
                            <template v-if="reviews[source.slug] === undefined || reviews[source.slug].total > 0">
                                <p class="stars">
                                    <stars :rating="reviews[source.slug].avg"></stars>&nbsp;
                                    <span class="text-muted ratings">({{reviews[source.slug].total}} {{$tc('reviews.reviews', reviews[source.slug].total)}})</span>
                                </p>

                                <p>
                                    {{$t('reviews.sourceScore', {source: source.label})}} {{reviews[source.slug].avg | fixedNumber(1)}}
                                </p>
                            </template>

                            <p v-else class="text-muted no-reviews">
                                <br/>
                                {{$t('reviews.noReviews')}}
                            </p>
                        </div>

                        <span v-else>
                            <p class="stars mock">Stjerne</p>
                            <p class="mock">
                                Lorem mocktekst ipsum est<br>
                                Ipsum est Lorem
                            </p>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    @import '~@/assets/scss/_vars.scss';

    .stars {
        font-size: 18px;
        margin-top: 12px;
    }

    .no-reviews {
        margin-top: 14px;
    }

    .sources {
        .ratings {
            font-size: 10pt;
        }

        p {
            font-size: 11pt;
        }

        &:last-child hr {
            display: none;
        }

        .box {
            display: table;
            width: 100%;

            .logo-wrapper {
                height: 40px;
                display: table-cell;
                vertical-align: middle;
                text-align: center;

                img {
                    display: inline-block;
                    max-width: 200px;
                    max-height: 30px;
                }

            }
        }
    }
</style>

<script>
    import Loader from '@/app/shared/components/Loader'
    import Stars from '@/app/reviews/components/Stars'
    import { mapGetters } from 'vuex'

    const ReviewService = require('@/services/reviews/ReviewService')

    import organization from '@/mixins/customer/organization'

    export default {
        mixins: [organization],

        data() {
            return {
                loaded: 0,
                reviews: {},
                sources: [
                    {
                        label: 'Facebook',
                        slug: 'facebook',
                        image: 'facebook',
                    },
                    {
                        label: 'Trustpilot',
                        slug: 'trustpilot',
                        image: 'trustpilot',
                    },
                    {
                        label: 'Google',
                        slug: 'google-my-business',
                        image: 'google-my-business',
                    }
                ]
            }
        },
        computed: {
            hasLoaded() {
                if (!this.loaded > 2) {
                    return false
                }

                return this.loaded === this.sources.length
            },

          ...mapGetters('identity', {
            identity: 'getIdentity'
          }),

          ...mapGetters('customer', {
            customer: 'getCustomer'
          }),
        },

        watch: {
            hasLoaded() {
                if (!this.hasLoaded) {
                    return
                }

                this.$emit('hasLoaded')
            }
        },

        mounted() {
            this.load()
        },

        methods: {
            load() {
                this.sources.forEach((source) => {
                    const data = {
                        limit: 1,
                        includes: ['source'],

                        sort: [
                            {
                                key: 'created',
                                direction: 'desc'
                            },
                            {
                                key: 'author',
                                direction: 'asc'
                            }
                        ],

                        filter_groups: [
                            {
                                filters: [
                                    {
                                        key: 'source.slug',
                                        value: source.slug,
                                        operator: 'eq'
                                    },
                                    {
                                        key: 'customer',
                                        operator: 'eq',
                                        value: this.customer.id,
                                    }
                                ]
                            }
                        ]
                    }

                    ReviewService.findAll(data, (response) => {
                        const review = response.data
                        const total = review.total

                        ReviewService.sum('rating', data, (response) => {
                            const sum = response.data.total

                            this.$set(this.reviews, source.slug, {
                                total: total,
                                avg: sum / total,
                            })

                            this.loaded++
                        })
                    })
                })
            },

            paginate(page) {
                this.page = page
            },

            reviewSourceImage(source) {
                return '/images/review-sources/' + source.image + '.png'
            }
        },

        components: {
            Loader,
            Stars
        }
    }
</script>
