<template>
    <span class="rating">
        <i class="fa fa-star" v-for="n in whole"></i>
        <i class="fa fa-star-half-o" v-for="n in half"></i>
        <i class="fa fa-star-o" v-for="n in empty"></i>
    </span>
</template>

<style lang="scss">
    .rating {
        color: #fac725;
    }
</style>

<script>
    export default {
        props: {
            rating: Number
        },

        data() {
            return {
                firstDecimal: 0
            }
        },

        mounted() {
            this.firstDecimal = (this.rating - Math.floor(this.rating)) * 10
        },

        computed: {
            whole: function () {
                if (this.firstDecimal <= 7) {
                    return Math.floor(this.rating)
                }

                return Math.ceil(this.rating)
            },

            half: function () {
                if (3 <= this.firstDecimal && this.firstDecimal <= 7) {
                    return 1
                }

                return 0
            },

            empty: function () {
                return 5 - this.whole - this.half
            }
        }
    }
</script>