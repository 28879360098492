<template>
    <div class="review">
        <div class="logo-container pull-left">
            <img :src="reviewSourceImage" class="img-responsive">
        </div>

        <small class="text-muted date text-right">{{$t('reviews.list.dateText')}} <br> {{review.created.date | moment('Do MMMM YYYY')}}</small>

        <div class="content-container pull-left">
            <p>
                <strong>{{review.author | decode}}</strong>
            </p>

            <p>
                <stars :rating="review.rating"></stars>
            </p>

            <div v-if="review.review !== null">
                <dot v-if="dot" :msg="review.review" :line="2" class="desc" @isDot="isDot"></dot>
                <p v-if="!dot" class="desc">{{review.review}}</p>
                <a class="show-more advice"  @click="showReponseAdvice(review.id)">{{$t('reviews.list.help')}}</a>
                <a v-if="moreText && dot" @click="dot = !dot" class="show-more">{{$t('reviews.list.readMore')}}</a>
                <a v-if="!dot" @click="dot = !dot" class="show-more">{{$t('reviews.list.close')}}</a>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .review {
        position: relative;

        .advice {
            margin-right: 8px;
            border: 1px solid #3d6fdc;
            padding: 4px 10px;
            border-radius: 3px;
        }

        .date {
            position: absolute;
            top: 0;
            right: 0;
        }

        .logo-container {
            width: 50px;
            margin-right: 15px;
        }

        .content-container {
            width: 80%;
        }

        .rating {
            color: #fac725;
        }
    }
</style>

<script>
    import Dot from 'vue-text-dot'
    import Stars from '@/app/reviews/components/Stars'

    export default {
        props: [
            'review',
            'hasLoaded'
        ],
        data() {
            return {
                isCollapsed: true,
                moreText: false,
                dot: true,
                sources: {
                    facebook: 'facebook',
                    trustpilot: 'trustpilot',
                    googleMyBusiness: 'google-my-business',
                }
            }
        },

        computed: {
            reviewSourceImage() {
                let sources = this.sources;
                let source = '';

                if (!this.review.source || !this.review.source.slug) {
                    return ''
                }

                switch (this.review.source.slug) {
                    case sources.facebook:
                        source = 'facebook';
                        break;

                    case sources.trustpilot:
                        source = 'trustpilot';
                        break;

                    case sources.googleMyBusiness:
                        source = 'google-my-business';
                        break;

                    default:
                        return '';
                        break;
                }

                return '/images/review-sources/' + source + '-compact.png';
            }
        },

        methods: {
            showReponseAdvice(id) {
                this.$router.push({ path: 'reviews', query: { reviewId: id }})
            },

            foldOut() {
                this.isCollapsed = false
            },

            isDot() {
                this.moreText = true
            }
        },
        components: {
            Dot,
            Stars
        }
    }
</script>
