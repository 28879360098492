<template>
    <div class="trustpilot-review">
        <integration logo="/images/onboarding/trustpilot.png" :border="true" :headline="$t('reviews.onboarding.trustpilot.headline')" :description="$t('reviews.onboarding.trustpilot.description')">
            <template v-if="!hasHealthyIntegration('Trustpilot review')">
                <router-link :to="{name: 'frontend.settings.integrations.trustpilot.setup'}"
                             class="btn btn-fill btn-primary btn-trustpilot"
                             :style="organizationBackgroundColor">
                    {{$t('onboarding.connect')}}
                </router-link>
            </template>

            <template v-else>
                <div class="connected connected-lg text-muted">
                    <span class="fa fa-check fa-fw"></span>
                    {{$t('onboarding.connected')}}
                </div>

                <br>
                <br>

                <div>
                    <remove-integration headline="Trustpilot" name="Trustpilot review"></remove-integration>
                </div>
            </template>
        </integration>
    </div>
</template>

<script>
    import Integration from '@/app/onboarding/components/integrations/Integration'
    import RemoveIntegration from '@/app/onboarding/components/integrations/RemoveIntegration'
    import hasIntegration from '@/mixins/integrations/hasIntegration'

    export default {
        mixins: [hasIntegration],

        computed: {
            organizationColor() {
                return {color:$org('colors.standard.integrations.connect')}
            },

            organizationBackgroundColor() {
                return {backgroundColor:$org('colors.standard.integrations.connect')}
            }
        },

        components: {
            Integration,
            RemoveIntegration,
        }
    }
</script>
