<template>
    <span class="pagination-text">
        {{from}} - {{to}} {{$t('pagination.of')}} {{total}}
    </span>
</template>

<style lang="scss" scoped>
    .pagination-text {

    }
</style>

<script>
  export default {
    name: 'PaginatonText',
    props: {
      total: Number,
      limit: Number,
      page: Number,
    },
    computed: {
      from: function() {
        return (this.page - 1) * this.limit + 1;
      },
      to: function() {
        let to = this.page * this.limit;
        if (to > this.total) {
          return this.total
        }
        return to;
      }
    },
  }
</script>