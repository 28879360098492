<template>
    <div class="row">
        <div class="col-xs-12">
            <card :headline="$t('reviews.distribution.headline')" :description="$t('reviews.distribution.description')" icon="pe-7s-star">
                <hr/>

                <div v-for="n in 5">
                    <div>
                        <p class="numberOfStars pull-left">{{6 - n}} {{$tc('reviews.stars', (6 - n))}}</p>

                        <div class="progress pull-left">
                            <div class="progress-bar progress-bar-rating" role="progressbar" :style="{width: percentage(6 - n) + '%'}">
                                <span class="sr-only">{{percentage(6 - n)}}% Complete</span>
                            </div>
                        </div>

                        <small class="ratingCount text-muted pull-left visible-lg">
                            {{stars[6 - n]}} {{$tc('reviews.reviews', stars[6 - n])}}
                        </small>

                        <div class="clearfix"></div>
                    </div>
                </div>

                <div class="clearfix"></div>
            </card>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .numberOfStars {
        width: 85px;

    }
    .progress {
        height: 10px;
        width: 50%;
        margin-top: 8px;
    }

    .progress-bar-rating {
        background-color: #fac725
    }

    .ratingCount {
        margin-left: 15px;
        margin-top: 7px;
    }

    .progress-bar {
        box-shadow: none;
    }
</style>

<script>
    import Loader from '@/app/shared/components/Loader'
    import Card from '@/app/shared/components/Card'
	import {mapGetters} from "vuex";

    const ReviewService = require('@/services/reviews/ReviewService')

    export default {
        data() {
            return {
                loaded: 0,
                stars: {},
                total: 0,
            }
        },

        mounted() {
            this.load()
        },

        computed: {
            hasLoaded() {
                return this.loaded === 5
            },

			...mapGetters('customer', {
				customer: 'getCustomer'
			})
        },

        methods: {
            load() {
                for (let i = 0; i < 5; i++) {
                    const stars = i + 1

                    const data = {
                        limit: 1,
                        includes: ['source'],

                        sort: [
                            {
                                key: 'created',
                                direction: 'desc'
                            },
                            {
                                key: 'author',
                                direction: 'asc'
                            }
                        ],

                        filter_groups: [
                            {
                                filters: [
                                    {
                                        key: 'rating',
                                        value: stars,
                                        operator: 'eq'
                                    },
                                    {
                                        key: 'customer',
                                        value: this.customer.id,
                                        operator: 'eq'
                                    }
                                ]
                            }
                        ]
                    }

                    ReviewService.findAll(data, (response) => {
                        const body = response.data

                        this.total += body.total

                        this.$set(this.stars, stars, body.total)

                        this.loaded++
                    })
                }
            },

            percentage(stars) {
                return this.stars[stars] / this.total * 100
            }
        },

        components: {
            Loader,
            Card
        }
    }
</script>
