<template>
    <card :headline="$t('reviews.list.reviews')">
        <div slot="header">
            <select class="form-control source-select hidden-xs" v-model="selectedSource">
                <option value="">{{$t('reviews.list.all')}}</option>
                <option value="facebook">Facebook</option>
                <option value="trustpilot">Trustpilot</option>
                <option value="google-my-business">Google</option>
            </select>
        </div>

        <div class="reviews">
            <div class="row">
                <div class="col-xs-12">
                    <div v-for="review in limit" v-if="!hasLoaded">
                        <hr>
                        <p class="mock">Anders Andersen</p>
                        <p class="mock">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla eros eros, feugiat et est eget, dapibus varius ligula. Etiam placerat, libero sit amet varius dapibus, ipsum turpis suscipit nulla, posuere dapibus ante urna a elit. "</p>
                    </div>

                    <hr>

                    <div v-for="review in reviews" v-if="hasLoaded">
                        <review :review="review"></review>
                        <div class="clearfix"></div>
                        <hr>
                    </div>

                    <div class="text-muted pull-left pagination-text">
                        <pagination-text :total="total" :limit="limit" :page="page"></pagination-text> {{$tc('reviews.reviews', total)}}
                    </div>

                    <pagination class="pull-right" :total="total" :limit="limit" :page="page" v-on:paginate="paginate"></pagination>
                </div>
            </div>
        </div>
    </card>
</template>

<style lang="scss" scoped>
    .pagination-text {
        margin-top: 8px;
    }

    .source-select {
        width: 180px;
    }
</style>

<script>
    import Card from '@/app/shared/components/Card'
    import Pagination from '@/app/layout/components/paginator/Pagination'
    import PaginationText from '@/app/layout/components/paginator/PaginationText'
    import Review from '@/app/reviews/components/list/Review'
    import Dot from 'vue-text-dot'
	import {mapGetters} from "vuex";

    const ReviewService = require('@/services/reviews/ReviewService')

    export default {
        data() {
            return {
                hasLoaded: false,
                reviews: [],
                limit: 3,
                page: 1,
                total: 0,
                isCollapsed: true,
                facebookSlug: 'facebook',
                trustpilotSlug: 'trustpilot',
                googleMyBusinessSlug: 'google-my-business',
                googleBusinessProfileSlug: 'google-business-profile',
                selectedSource: ''
            }
        },
        watch: {
            page() {
                this.load()
            },

            selectedSource() {
                this.page = 1
                this.load()
            }
        },

        mounted(){
            this.load()
        },

		computed: {
			...mapGetters('customer', {
				customer: 'getCustomer'
			})
		},

        methods: {
            load() {
                this.hasLoaded = false

                const data = {
                    includes: ['source'],
                    limit: this.limit,
                    page: this.page,
                    filter_groups: [
                        {
                            filters: [
								{
									key: 'customer',
									operator: 'eq',
									value: this.customer.id
								}
							]
                        }
                    ],
                    sort: [
                        {
                            key: 'written',
                            direction: 'desc'
                        },
                        {
                            key: 'author',
                            direction: 'asc'
                        }
                    ]
                }

                switch (this.selectedSource) {
                    case 'facebook':
                        data['filter_groups'][0]['filters'].push({
                            key: 'source.slug',
                            value: this.facebookSlug,
                            operator: 'eq'
                        })
                        break;

                    case 'trustpilot':
                        data['filter_groups'][0]['filters'].push({
                            key: 'source.slug',
                            value: this.trustpilotSlug,
                            operator: 'eq'
                        })
                        break;

                    case 'google-my-business':
                        data['filter_groups'][0]['filters'].push({
                            key: 'source.slug',
                            value: this.googleMyBusinessSlug,
                            operator: 'eq'
                        })
                        break;

                    case 'google-business-profile':
                        data['filter_groups'][0]['filters'].push({
                            key: 'source.slug',
                            value: this.googleBusinessProfileSlug,
                            operator: 'eq'
                        })
                        break;
                }

                ReviewService.findAll(data, response => {
                    const body = response.data

                    this.total = body.total
                    this.reviews = body.rows

                    let sources = this.reviews
                        .slice(0)
                        .map(obj => obj.source)

                    this.sources = Array.from(new Set(sources))

                    this.hasLoaded = true
                });
            },

            paginate(page) {
                this.page = page;
            }
        },
        components: {
            Card,
            Pagination,
            PaginationText,
            Review,
            Dot,
        }
    }
</script>
